import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["wanSelect", "wanFieldsContainer", "wanConfigTitle"]

  connect() {
    this.wanInfo = JSON.parse(document.getElementById("wan-info-data").getAttribute("data-wan-info"))
    this.form = document.getElementById("wan-config-form")
    if (this.wanInfo.length > 0) {
      this.populateWanSelect()
      this.updateFormForSelectedWan()
    }
  }

  populateWanSelect() {
    this.wanSelectTarget.innerHTML = ""
    this.wanInfo.forEach(wan => {
      let uniqueIdentifier = wan.addressing_type ? `${wan.name} - ${wan.addressing_type}` : wan.name
      let option = document.createElement("option")
      option.value = uniqueIdentifier
      option.textContent = uniqueIdentifier
      this.wanSelectTarget.appendChild(option)
    })
    if (this.wanInfo.length > 0) {
      let activeWans = this.wanInfo.filter(w => w.is_active)
      let defaultWan = activeWans.find(w => w.addressing_type?.toLowerCase() === "pppoe") ||
                       activeWans.find(w => w.addressing_type?.toLowerCase() === "static") ||
                       activeWans.find(w => w.addressing_type?.toLowerCase() === "dynamic") ||
                       activeWans.find(w => w.name?.toLowerCase().includes("pppoe")) ||
                       activeWans.find(w => w.name?.toLowerCase().includes("l2tp")) ||
                       activeWans.find(w => w.name?.toLowerCase().includes("pptp")) ||
                       this.wanInfo[0]
      let uniqueIdentifier = defaultWan.addressing_type ? `${defaultWan.name} - ${defaultWan.addressing_type}` : defaultWan.name
      this.wanSelectTarget.value = uniqueIdentifier
      this.renderWanFields(defaultWan)
    }
  }

  updateFormForSelectedWan() {
    const selectedIdentifier = this.wanSelectTarget.value
    const selectedWan = this.wanInfo.find(w => {
      let wanIdentifier = w.addressing_type ? `${w.name} - ${w.addressing_type}` : w.name
      return wanIdentifier === selectedIdentifier
    })
    if (!selectedWan) {
      this.wanFieldsContainerTarget.innerHTML = ""
      this.wanConfigTitleTarget.textContent = "No matching WAN found"
      return
    }
    this.renderWanFields(selectedWan)
  }

  renderWanFields(wan) {
    this.wanFieldsContainerTarget.innerHTML = ""
    this.wanConfigTitleTarget.textContent = `${wan.name} (${wan.connection_status || "Unknown"})`
    const deviceIndex = this.wanInfo.findIndex(w => w.name === wan.name)
    let detected = this.detectAddressingType(wan)
    if (detected) {
      this.wanFieldsContainerTarget.appendChild(
        this.createHiddenInput(`device_params[${deviceIndex}][addressing_type]`, detected)
      )
    } else {
      this.wanFieldsContainerTarget.appendChild(
        this.createTextField(deviceIndex, "Addressing Type", "addressing_type", "Unknown", wan)
      )
    }
    if (detected === "Static") {
      this.renderStaticFields(deviceIndex, wan)
    } else if (detected === "PPPoE") {
      this.renderPPPoEFields(deviceIndex, wan)
    }
    const enableField = this.createSelectField(deviceIndex, "WAN Enabled", "enable", [{ value: "true", text: "Enabled" }, { value: "false", text: "Disabled" }], wan.enable ? "true" : "false", wan)
    this.wanFieldsContainerTarget.appendChild(enableField)
  }

  renderStaticFields(deviceIndex, wan) {
    const staticGroup = document.createElement("div")
    staticGroup.id = "wan-manual-fields"
    staticGroup.style.display = "block"
    staticGroup.appendChild(this.createTextField(deviceIndex, "External IP Address", "ip_address", wan.ip_address || "", wan))
    staticGroup.appendChild(this.createTextField(deviceIndex, "Default Gateway", "gateway", wan.gateway || "", wan))
    staticGroup.appendChild(this.createTextField(deviceIndex, "DNS Servers", "dns_servers", wan.dns_servers || "", wan))
    this.wanFieldsContainerTarget.appendChild(staticGroup)
  }

  renderPPPoEFields(deviceIndex, wan) {
    const pppoeGroup = document.createElement("div")
    pppoeGroup.id = "wan-pppoe-fields"
    pppoeGroup.style.display = "block"
    pppoeGroup.appendChild(this.createTextField(deviceIndex, "Username", "username", wan.username || "", wan))
    pppoeGroup.appendChild(this.createPasswordField(deviceIndex, "Password", "password", wan.password || "", wan))
    this.wanFieldsContainerTarget.appendChild(pppoeGroup)
  }

  toggleAddressingFields(type) {
    const staticFields = document.getElementById("wan-manual-fields")
    const pppoeFields = document.getElementById("wan-pppoe-fields")
    if (staticFields && pppoeFields) {
      staticFields.style.display = type.toLowerCase() === "static" ? "block" : "none"
      pppoeFields.style.display = type.toLowerCase() === "pppoe" ? "block" : "none"
    }
  }

  detectAddressingType(wan) {
    if (wan.username) {
      return "PPPoE"
    }
    if (wan.addressing_type || wan.name) {
      const type = wan.addressing_type.toLowerCase()
      const wan_name = wan.name.toLowerCase()
      if (type.includes("pppoe") || wan_name.includes("pppoe")) return "PPPoE"
      if (type.includes("l2tp") || wan_name.includes("l2tp")) return "PPPoE"
      if (type.includes("pptp") || wan_name.includes("pptp")) return "PPPoE"
      if (type.includes("static")) return "Static"
      if (type.includes("dynamic")) return "Dynamic"
    }
    return ""
  }

  createTextField(deviceIndex, labelText, fieldName, value, wan) {
    const group = this.createFormGroup()
    const label = this.createLabel(labelText, `wan-field-${fieldName}`)
    const input = document.createElement("input")
    input.type = "text"
    input.id = `wan-field-${fieldName}`
    input.name = `device_params[${deviceIndex}][${fieldName}]`
    input.value = value
    input.classList.add("form-control")
    input.setAttribute("data-original-value", value)
    group.appendChild(label)
    group.appendChild(this.wrapInput(input))
    let pathValue = (wan.paths && wan.paths[fieldName]) ?
      wan.paths[fieldName] :
      this.generateDefaultPath(wan, fieldName)
    group.appendChild(this.createHiddenInput(`device_params[${deviceIndex}][paths][${fieldName}]`, pathValue))
    if (wan.writable && wan.writable[fieldName] === false) {
      input.disabled = true
    }
    return group
  }

  createPasswordField(deviceIndex, labelText, fieldName, value, wan) {
    const group = this.createFormGroup()
    const label = this.createLabel(labelText, `wan-field-${fieldName}`)
    group.appendChild(label)
    const inputGroup = document.createElement("div")
    inputGroup.classList.add("input-group", "mb-2", "has-feedback")
    inputGroup.setAttribute("data-controller", "password-visibility")
    const input = document.createElement("input")
    input.type = "password"
    input.id = `wan-field-${fieldName}`
    input.name = `device_params[${deviceIndex}][${fieldName}]`
    input.value = value
    input.classList.add("form-control")
    input.setAttribute("data-original-value", value)
    input.setAttribute("data-password-visibility-target", "input")
  
    inputGroup.appendChild(input);

    const inputGroupAppend = document.createElement("div")
    inputGroupAppend.classList.add("input-group-append")
  
    const inputGroupText = document.createElement("div")
    inputGroupText.classList.add("input-group-text")
  
    const eyeIcon = document.createElement("i");
    eyeIcon.classList.add("fas", "fa-eye", "cursor-pointer", "hidden")
    eyeIcon.setAttribute("data-action", "click->password-visibility#toggle")
    eyeIcon.setAttribute("data-password-visibility-target", "icon")
  
    const eyeSlashIcon = document.createElement("i")
    eyeSlashIcon.classList.add("fas", "fa-eye-slash", "cursor-pointer")
    eyeSlashIcon.setAttribute("data-action", "click->password-visibility#toggle")
    eyeSlashIcon.setAttribute("data-password-visibility-target", "icon")
  
    inputGroupText.appendChild(eyeIcon)
    inputGroupText.appendChild(eyeSlashIcon)
    inputGroupAppend.appendChild(inputGroupText)
    inputGroup.appendChild(inputGroupAppend)
  
    group.appendChild(this.wrapInput(inputGroup))
    let pathValue = (wan.paths && wan.paths[fieldName]) ?
      wan.paths[fieldName] :
      this.generateDefaultPath(wan, fieldName)
    group.appendChild(this.createHiddenInput(`device_params[${deviceIndex}][paths][${fieldName}]`, pathValue))
    if (wan.writable && wan.writable[fieldName] === false) {
      input.disabled = true
    }
    return group
  }

  createSelectField(deviceIndex, labelText, fieldName, options, selectedValue, wan) {
    const group = this.createFormGroup()
    const label = this.createLabel(labelText, `wan-field-${fieldName}`)
    const select = document.createElement("select")
    select.id = `wan-field-${fieldName}`
    select.name = `device_params[${deviceIndex}][${fieldName}]`
    select.classList.add("form-control")
    select.setAttribute("data-original-value", selectedValue)
    options.forEach(opt => {
      const option = document.createElement("option")
      option.value = opt.value
      option.textContent = opt.text
      if (opt.value === selectedValue) {
        option.selected = true
      }
      select.appendChild(option)
    })
    group.appendChild(label)
    group.appendChild(this.wrapInput(select))
    if (wan.paths && wan.paths[fieldName]) {
      group.appendChild(this.createHiddenInput(`device_params[${deviceIndex}][paths][${fieldName}]`, wan.paths[fieldName]))
    }
    return group
  }

  createHiddenInput(name, value) {
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = name
    input.value = value
    return input
  }

  createFormGroup() {
    const group = document.createElement("div")
    group.classList.add("form-group", "row")
    return group
  }

  createLabel(text, htmlFor) {
    const label = document.createElement("label")
    label.classList.add("col-sm-3", "col-form-label")
    label.setAttribute("for", htmlFor)
    label.textContent = text
    return label
  }

  wrapInput(element) {
    const container = document.createElement("div")
    container.classList.add("col-sm-9")
    container.appendChild(element)
    return container
  }

  generateDefaultPath(wan, fieldName) {
    if (wan.paths && wan.paths.enable) {
      const lastDot = wan.paths.enable.lastIndexOf('.');
      if (lastDot !== -1) {
        const basePath = wan.paths.enable.substring(0, lastDot);
        return `${basePath}.${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}`;
      }
    }
    throw new Error("Missing base_path in the WAN JSON Form data.");
  }
}
