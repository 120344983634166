import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "dateRangeField", "select" ]

  populateDateRangeField(event) {
    const listItem = event.currentTarget

    this.dateRangeFieldTarget.value = listItem.dataset.date
    this.selectTarget.value = listItem.dataset.value
  }
}
