import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["addButton"];

  connect() {
    this.updateButtonVisibility();
  }

  addCharge() {
    const hiddenGroup = this.getHiddenGroups()[0]; 
    if (hiddenGroup) hiddenGroup.classList.remove("hidden");
    this.updateButtonVisibility();
  }

  updateButtonVisibility() {
    const hasHiddenGroups = this.getHiddenGroups().length > 0;
    if (!hasHiddenGroups){
      this.addButtonTarget.classList.add("hidden");
    }
  }

  getHiddenGroups() {
    return [...this.element.querySelectorAll('[data-charge="group"].hidden')];
  }
}