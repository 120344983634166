import { Controller } from '@hotwired/stimulus'
import intlTelInput from 'intl-tel-input'

// We currently use version 17.
// https://github.com/jackocnr/intl-tel-input/tree/v17

const DUMMY_NUMBER = "912222333444"
const NOT_PROVIDED = "Not Provided"

export default class extends Controller {
  static targets = ['countryCodeInput']

  connect() {
    let iti = null
    const countryCodeInput = this.countryCodeInputTarget
    const allowChangingCountryCode = countryCodeInput.dataset.allowChangingCountryCode === "true"

    iti = intlTelInput(countryCodeInput, {
      preferredCountries: [countryCodeInput.dataset.initialCountry],
      initialCountry: countryCodeInput.value !== NOT_PROVIDED ? countryCodeInput.dataset.initialCountry : null,
      separateDialCode: true,
      formatOnDisplay: false,
      allowDropdown: allowChangingCountryCode ? true : false
    })

    let data = iti.getSelectedCountryData()
    let dialCode = data.dialCode

    if (countryCodeInput.value !== NOT_PROVIDED) {
      countryCodeInput.value = countryCodeInput.value.slice(dialCode.length)
    }

    countryCodeInput.addEventListener("countrychange", (event) => {
      data = iti.getSelectedCountryData()
    })

    countryCodeInput.form.addEventListener("submit", (event) => {
      if (Object.keys(data).length === 0) {
        iti.setCountry(countryCodeInput.dataset.initialCountry)

        dialCode = iti.getSelectedCountryData().dialCode
      } else {
        dialCode = data.dialCode
      }

      if (countryCodeInput.value === NOT_PROVIDED ||
        countryCodeInput.value === "" ||
        countryCodeInput.value.slice(0, dialCode.length) === dialCode ||
        countryCodeInput.value.slice(0, dialCode.length * 2) === `${dialCode}${dialCode}`)
      {
        return
      } else {
        countryCodeInput.value = `${dialCode}${countryCodeInput.value}`
      }
    })
  }

  addNotProvidedText(event) {
    this.countryCodeInputTarget.value = NOT_PROVIDED
  }
}
