import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    setInterval(this.updateTime, 1000, this.element)
  }

  updateTime(element) {
    const options = {
      timeZone: element.dataset.timeZone,
      hour: "numeric",
      minute: "numeric",
      second: "numeric"
    }

    element.innerHTML = new Intl.DateTimeFormat("en-US", options).format(new Date())
  }
}
