import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'next' ]

  append() {
    let url = this.data.get("url")
    let next = this.nextTarget
    fetch(url)
      .then(response => response.text())
      .then(data => next.innerHTML = data)
      .catch(error => console.error('Error:', error));
  }
}
