import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['element']

  // Example usage is down below
  // search 'data-controller="forms--toggle" if you want to check it at app/views/location_reports/index.html.erb
  // For select there should be a new method
  toggleElement(event) {
    const element = this.elementTarget

    if (event.currentTarget.type == 'radio' || event.currentTarget.type == 'checkbox') {
      if (event.currentTarget.checked) {
        if (event.currentTarget.dataset.name == element.dataset.name) {
          element.classList.remove('hidden')
        }
        else {
          element.classList.add('hidden')
        }
      }
    }
  }
}

// ***Checkboxes that will either toggle or hide div that you want to toggle

// <%= f.radio_button :data_usage_period, 'daily', { 'data-action': 'forms--toggle#toggleElement', 'data-name': 'daily', checked: true } %>
// <%= f.label 'Daily', class: 'mb-1 mr-3' %>

// <%= f.radio_button :data_usage_period, 'monthly', { 'data-action': 'forms--toggle#toggleElement', 'data-name': 'monthly' } %>
// <%= f.label 'Monthly', class: 'mb-1' %>

// ***Div that you want to toggle

// <div data-forms--toggle-target="element" data-name="daily">
// </div>
