// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

import PasswordVisibility from "@stimulus-components/password-visibility";
import Clipboard from "@stimulus-components/clipboard";

window.Stimulus = Application.start();
const context = require.context("../controllers", true, /\.js$/);
Stimulus.load(definitionsFromContext(context));

Stimulus.register("password-visibility", PasswordVisibility);
Stimulus.register("clipboard", Clipboard);
